<!--
 * @Description: 客户关系
 * @Author: ChenXueLin
 * @Date: 2021-10-08 09:19:15
 * @LastEditTime: 2021-11-10 10:42:07
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="wrap">
    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table
        border
        :data="corpRelations"
        highlight-current-row
        height="300px"
      >
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.$index + 1 }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="120">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList()"
              @command="handleOperate($event, scope.$index)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 表格 end -->
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
export default {
  name: "customerRelations",
  data() {
    return {
      columnData: [
        {
          fieldName: "otherCorpName",
          display: true,
          fieldLabel: "客户名称",
          width: 300,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpRelationName",
          display: true,
          fieldLabel: "客户关系",
          width: 300,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 300,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ] //表头
    };
  },
  props: {
    corpRelations: {
      type: Array,
      default: () => []
    }
  },
  mixins: [base, listPage],

  computed: {},

  mounted() {},

  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "删除",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, index) {
      if (val.id == 1) {
        this.$emit("handleDelete", { type: 2, index: index });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap {
  margin-top: 10px;
}
</style>
